.v-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.status-resolved,
.status-success,
.status-confirmed,
.status-completed,
.status-verified,
.status-kyc,
.status-approved,
.status-enable,
.status-opening {
  background: #c8e6c9;
  color: #256029;
}

.status-error,
.status-declined,
.status-declined_verified,
.status-init,
.status-closed {
  background: #ffcdd2;
  color: #c63737;
}

.status-pending,
.status-pending_kyc,
.status-pending_verified,
.status-disable {
  background: #feedaf;
  color: #8a5340;
}

.status-new {
  background: #b3e5fc;
  color: #23547b;
}

.status-blocked {
  background: #eccfff;
  color: #694382;
}

.status-proposal {
  background: #ffd8b2;
  color: #805b36;
}

.v-text-logo {
  background: linear-gradient(rgb(20, 91, 166), rgb(237, 247, 248));
  background-clip: text;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
}

.p-fileupload-choose {
  padding-right: 9px;
}

.privacy-public,
.privacy-friend,
.privacy-friend_except,
.privacy-friend_detail,
.privacy-acquaintance {
  background: #c8e6c9;
  color: #256029;
}

.privacy-only_me {
  background: #feedaf;
  color: #8a5340;
}

.border {
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
}
